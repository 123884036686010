$(document).on('turbolinks:load', function() {
  $(".customer-info").click(function(event) {
    if (event.target.tagName == "TD") {
      window.location = $(this).data("href")
    }
  });

  // Sorting arrows
  checkIfSorted();
  
  // Below code for search bar
  
  // If the user clicks the search icon
  $("#search-button").click(function() {
    redirectToSearch($("#search-input").val()) 
  })
  // If the user hits the enter key
  $("#search-input").on("keypress", function(e) {
    if(e.keyCode == 13) {
      redirectToSearch($("#search-input").val())
    }
  })

  // If the user clicks the x button
  $("#clear-search").click(function(key) {
    redirectToSearch("")
  })

  // If the user clicks sorting arrows
  $(".sort").click(function(event) {
    // To handle clicking on font awesome path tag instead of span tag
    click_target = event.target.tagName == "path" ? event.target.parentElement : event.target;
    redirectToSort(click_target.dataset.type)
    
  })
});

// Add the search parameter to the current page and refresh
function redirectToSearch(value) {
  var searchParams = new URLSearchParams(window.location.search);
  searchParams.set("search", value);
  searchParams.set("page", "1");
  window.location.search = searchParams.toString();
}

// Add the sort parameter
function redirectToSort(value) {
  var searchParams = new URLSearchParams(window.location.search);
  var value_split = value.split(" ");
  // if the sorting being selected is already selected, refresh to original page
  if (searchParams.toString().includes(value_split[0]) && searchParams.toString().includes(value_split[1])) {
    if (searchParams.toString().includes("date") && !searchParams.toString().includes("_date")){
      window.location = window.location.href.split("&")[0];
    }else{
      window.location = window.location.href.split("?")[0];
    }

    return;
  }
  searchParams.set("sort", value);
  searchParams.set("page", "1");
  window.location.search = searchParams.toString();
}

function checkIfSorted(){
  var searchParams = new URLSearchParams(window.location.search);

  let sortParam = searchParams.get("sort")
  // If there is no search params, exit function
  if(sortParam == null){
    return
  }

  let sortValue = sortParam.split(" ")
  let column = sortValue[0].replace(".", "_")
  let sortType = sortValue[1]
  let direction = (sortType == "ASC") ? "up" : "down"

  $(`#${column}-header .sort-${direction}`)[0].classList.add("text-primary")
}